<template>
  <div class="container">
    <img src="@/statics/image/becom-member-header.png" alt="头部封面" class="cover">

    <div class="body">
      <p class="section">
        我是深读行·读书会发起人新竹，为了让您简单清晰了解深读行，读书会，接下来我用
      </p>

      <div class="section introduce-section">
        <div class="item">
          <h2 class="title">WHO? </h2>
          <p class="content">深读行是谁?我们的理念是什么?</p>
        </div>
        <div class="item">
          <h2 class="title">WHY?</h2>
          <p class="content">希望深读行·读书会是一种什么状态的读书会?</p>
        </div>
        <div class="item">
          <h2 class="title">WHAT? </h2>
          <p class="content">深读行能给会员带来什么有价值的服务? </p>
        </div>
      </div>



      <SdxNumberedIndexVue number="01" />
      <SdxSubtitleVue text="我们的理念是什么？ " />
      <p class="section">深读行·读书会是一个会员制、去中心化、自发组织、 线上线下相结令的读书会社群交流平台。平台活动包 括但不限于书籍交流、电影交流、行业交流、文创交
        流，自我探索交流、亲子共读、换书活动、小组读书 打卡、茶话会文旅分享、文化创意活动等。</p>

      <img src="@/statics/image/cover1.jpg" alt="cover" class="cover">

      <div class="section">
        <ul class="index-list">
          <li class="item">
            <p class="label">深读行的理念</p>
            <p class="content">深读经典，见行美好</p>
          </li>

          <li class="item">
            <p class="label">深读经典</p>
            <p class="content">经典好书需要时间和耐心来深入品读</p>
          </li>
          <li class="item">
            <p class="label">见行美好</p>
            <p class="content">美好生活来自生活和工作的知行合一</p>
          </li>
        </ul>

      </div>

      <p class="section">
        我们期许深读行·读书会可以让大家在经典好书的深度品读中，有同频共振的书友一起交流，碰撞新想法，在见行美好生活的知行令一实践中，有志同道令的书友一起复盘总结好做法。
      </p>

      <SdxNumberedIndexVue number="02" />
      <SdxSubtitleVue text="我们会是怎么的读书状态？" />
      <p class="section">
        我觉得自已是幸运的。求学阶段，师长让我养成了早读的习惯，这个习惯我保持了十年。工作以后遇到一群爱好读书交流的朋友，大家在一起无功利目的，无拘无束畅聊阅读收获，让我感受到坚持早读和坚持参加读书会可以带来增长知识、减少内耗，打开思维边界，看到思维盲区的那种喜悦与惊喜。不以功利为追求的读书交流会，反而有更大裨益和满满的收获感。当我走出参加一些其他读书会，看到这么几种状况:有以读书交流为名，销售推广渠道为实;纯公益次事公，或许是内容准备和受众匹配的缺失，会有收获刚不确定的美中不足;中心化主讲人的读书会，但中心化带来的小圈子化，或许因为缺乏更多的源头活水，会有参与者热情无法持续的遗憾。我认为内耗增加、压力增大，安全感匮乏的当下，我们是需要读书会的。深读行，读书会希望和全国的阅读爱好者一起探寻如何持续办好有生命力，能持续给书友带来收获感满满的读书会。
      </p>

      <img src="@/statics/image/cover2.png" alt="山水图" class="cover section">


      <SdxNumberedIndexVue number="03" />
      <SdxSubtitleVue text="我们的套餐详情" />
      <p class="section">加入深读行.读书会,你可以收获什么? 深读行·读书会现阶段推出两种类型会员。</p>
      <div class="section">
        <ul class="index-list">
          <li class="item">
            <p class="label" style="font-weight: bold;">个人会员</p>
            <p class="content">365元/年/一人/一天一元</p>
          </li>

          <li class="item">
            <p class="label" style="font-weight: bold;">家庭会员</p>
            <p class="content">720元/年/三人(两大人，一孩子)</p>
          </li>
        </ul>

      </div>

      <p class="section">
        推出家庭会员，我们希望深读行读书会可以带动家庭亲 子共读的氛围和习惯。<span style="font-weight: bold;">推出家庭会员，我们希望深读行读书会可以带动家庭亲 子共读的氛围和习惯。</span>
      </p>

      <img src="@/statics/image/cover3.png" alt="山水图" class="cover section">


      <SdxNumberedIndexVue number="04" />
      <SdxSubtitleVue text="深读行会员权益介绍" />
      <p class="section">加入深读行.读书会,你可以收获什么? 深读行·读书会现阶段推出两种类型会员。</p>
      <div class="section">
        <ul class="index-list-ul">
          <li class="item">
            <p class="label">无限次报名和发起线上线下全国读书会活动</p>
            <p class="content">可以无限次报名和发起平台的各类读书交流活动。针对家庭用户，除了可以参加个人会员的所有活动外，也可以参加和发起带孩子一起参加的家庭亲子共读活动。</p>
          </li>

          <li class="item">
            <p class="label">无限次发起和加入读书小组，在小组内每日读书打卡功能</p>
            <p class="content">可以无限次报名和发起平台的各类读书交流活动。针对家庭用户，除了可以参加个人会员的所有活动外，也可以参加和发起带孩子一起参加的家庭亲子共读活动。</p>
          </li>

          <li class="item">
            <p class="label">阅读其他读书会成员读书打卡、读书笔记分享、活动收获笔记内容</p>
            <p class="content">有些读书会活动没有报上名，可以通过阅读其他会员的活动收获分享笔记，如临其境。</p>
          </li>

          <li class="item">
            <p class="label">无限次借助平台参加和发起换书活动</p>
            <p class="content">
              书籍互换互动，目的是让好书以最低碳的方式流通。既可以保持我们爱看纸质书的习惯，又可以免去买书后要定期归整纸质书的烦恼，同时又可结交一群志同道合、可持续碰撞的书友，实现一本纸质书最大化的价值。</p>
          </li>
          <li class="item">
            <p class="label">加入你所在城市的同城会员社群,一起同城持续交流，形成真实社交圈子。</p>
            <p class="content">
              书籍互换互动，目的是让好书以最低碳的方式流通。既可以保持我们爱看纸质书的习惯，又可以免去买书后要定期归整纸质书的烦恼，同时又可结交一群志同道合、可持续碰撞的书友，实现一本纸质书最大化的价值。</p>
          </li>
          <li class="item">
            <p class="label">无限次参加和发起行业（同行或者跨行）交流活动。</p>
            <p class="content">深读行·读书会倡导不仅仅只读文字之书，更要读社会之书——通过参加和发起行业交流会，帮助书友读懂不同行业，以赋能知行合一。</p>
          </li>

        </ul>

      </div>



      <!-- 结束 -->
      <div class="end-section">
        <div class="divider"></div>
        <span>END</span>
        <div class="divider"></div>
      </div>



    </div>

    <div class="fix-bottom">
      <button class="join-btn" @click="handleJoinMember">
        成为会员
      </button>
    </div>
  </div>
</template>

<script setup>

import SdxNumberedIndexVue from '@/components/SdxNumberedIndex.vue';
import SdxSubtitleVue from '@/components/SdxSubtitle.vue';






const handleJoinMember = () => {
  wx.miniProgram.navigateTo({ url: "/pagesA/pages/login/login" })
}
</script>




<style lang="less">
.container {
  width: 100vw;

  // 整体文字样式设置

  font-size: @sdx1-font-size;
  line-height: @sdx1-line-height;
  letter-spacing: @sdx1-letter-spacing;


  .fix-bottom {
    width: 100vw;
    height: 82px;
    background: #FFFFFF;
    box-shadow: 3px 2 3px 1px rgba(0, 0, 0, 0.16);
    border: 1px solid #FFFFFF;

    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;


    .join-btn {
      width: 170px;
      height: 42px;
      background: #7494FF;
      border-radius: 20px;

      border: none;

      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .body {
    padding: 0 20px;

    margin-top: 22px;

    padding-bottom: 100px;

    .title {
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;

      color: @sdx1-color-primary;
      margin-bottom: 12px;
    }

    .section {
      margin-bottom: @sdx1-section-gap;
    }

    .introduce-section {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .item {

        .content {
          text-align: center;
        }
      }
    }

    /* 列表容器样式 */
    .index-list {
      list-style-type: disc;
      counter-reset: item;
      padding-left: 40px;

      color: @sdx1-color-primary;


      li {
        margin-bottom: 20px;

        .label {
          color: @sdx1-text-color-primary;
        }

        .content {
          display: inline;
          color: @sdx1-color-primary;
          padding-bottom: 12px;
          border-bottom: 1px solid #EAEAEA;

        }
      }
    }

    .index-list-ul {
      list-style-type: decimal;
      counter-reset: item;
      padding-left: 20px;
      color: @sdx1-color-primary;

      li {
        margin-bottom: 20px;



        .content {
          display: inline;
          color: @sdx1-text-color-primary;
        }
      }
    }

    .cover {
      object-fit: cover;
    }



    .qrcode-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .qrcode {
        width: 98px;
        height: auto;
      }

      .qrcode-text {
        p {
          text-align: right;
        }
      }
    }

    .end-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      margin-bottom: 20px;

      .divider {
        width: 40px;
        height: 1px;
        background: #408E7C;
      }

      span {
        color: @sdx1-color-primary;

        font-weight: bold;
        font-size: @sdx1-font-size-sm;


      }
    }




  }


}
</style>