<template>
  <div class="numbered-index">
    <span class="number">{{ index }}</span>
    <div class="mask"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    index: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="less" scoped>
.numbered-index {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #408E7C;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 22px;

  position: relative;
  z-index: 2;

  .mask {
    width: 100%;
    height: 100%;
    background: #99D9CB;
    opacity: 0.15;

    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 1;
  }




  .number {
    font-weight: 400;
    font-size: 18px;
    color: #408E7C;
  }
}
</style>