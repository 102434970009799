<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'App',

});
</script>

<style lang="less">

.app {
  width: 100vw;
  height: 100vh;
}
</style>