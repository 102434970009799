<template>
    <div class="numbered-index">
        <span class="number">{{ number }}</span>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        number: {
            type: String,
            required: true
        }
    }
});
</script>

<style lang="less" scoped>


.numbered-index {


    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #408E7C;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    margin-bottom: 10px;


    .number {
        font-weight: 400;
        font-size: 18px;
        color: #408E7C;


    }
}
</style>