<template>
  <div class="container">
    <img src="@/statics/image/becom-parenter-header.png" alt="头部封面" class="cover">

    <div class="body">
      <p class="sdx1-text-underline section">
        你好，见字如面。<br>我是深读行·读书会发起人新竹。
      </p>

      <p class="section">在上一封信中，我们聊了我们发起深读行·读书会，从深读行取名到理念（深读经典，见行美好)，再到提出我们期许的读书会的七个价值观和状态以及为会员书友提供的具体会员服务（详见小程序首页成为会员）。这里我想聊一聊的是我们发超因队的初心，我们希望深读行·读书会可以为会员和社会带来什么，和深读行·读书会全国分会共建计划。</p>

      <p class="section">
        我内心期许深读行．读书会借助读书会活动的举办，可以带来这么一些带动作用。
      </p>

      <div class="section">
        <p class="sdx1-margin-bottom-20">下文介绍读书会带动作用主要分为三部分：</p>
        <ul class="index-list">
          <li>带动经典好书的深度阅读与交流</li>
          <li>带动经典好书的深度阅读与交流</li>
          <li>带动经典好书的深度阅读与交流</li>
        </ul>
      </div>


      <SdxNumberedIndexVue number="01" />
      <SdxSubtitleVue text="带动经典好书的深度阅读与交流" />
      <p class="section">带动经典好书的深度阅读和交流。短视频时代，专注和耐心变得越发稀铁，而经典好书需要有酎心深度品读。我们希望借助深读行·读书会可以影响更多人有耐心读完经典，多角度一起共读经典好书。</p>


      <SdxNumberedIndexVue number="02" />
      <SdxSubtitleVue text="带动家庭孩子共读" />
      <p class="section">带动经典好书的深度阅读和交流。短视频时代，专注和耐心变得越发稀铁，而经典好书需要有酎心深度品读。我们希望借助深读行·读书会可以影响更多人有耐心读完经典，多角度一起共读经典好书。</p>


      <SdxNumberedIndexVue number="03" />
      <SdxSubtitleVue text="倡导读书交流理念" />
      <p class="section">倡导知行会一的读书交流理念。在自己真实生活和 身处的具体行业工作中，践行所知所学。在深读行 读书会，我们除了鼓励书友发超读书交流外，也鼓 励发起读懂不同行业的行业交流会。</p>

      <img src="@/statics/image/cover1.jpg" alt="图片" class="cover">

      <p class="section">
        新竹和深读行小伏件认为在副业中探爭生命热情也是实现知行台一的方式之一。如果你已切身体验深读行•读书会活动，收获了属手自己的喜悦，认同我们的理念，并有热情把读书会作为一种生活方式带给身边更多情投意合，志同道令的朋友。欢迎你中请成为深读行•读书会的全国各地分会长。我们期许有缘分的书友在成为深读行．读书会全国分会长的实践中，把生活爱好和副业收入相结台，实现知行令一的蜕变。
      </p>


      <SdxTextIndexVue index="介绍"></SdxTextIndexVue>

      <p class="section">
        我们不需要你有专业技能。我们在分会长的不同阶段 和问题会引入不同的方法培训。比起专业技能，我们 更期许来自全国的分会长是一同成长的同班同学 （当然如果你有新媒体创作的热情和才华，是很好的 起跑助力点）
      </p>

      <SdxTextIndexVue index="其他"></SdxTextIndexVue>

      <p class="section">
        我们不需要你繳纳任何加盟费用。此超加盤费用，我们更在乎的是你是否有和把参加和办好读书会作为自己和家人的一种生活方式的热情；我们没有商业气息液厚的KPI和班味十足的硬性工作时间要求。比超KP1，我们更期许你和我们一样，有责任感，和很在乎每一场活动中书友们的收获感和体验感。
      </p>

      <p class="sdx1-text-underline section">
        如你有兴趣，欢迎你扫描我的微信二维码详细了解我 们的深读行•读书会全国分会共建计划期待与你一起 深读行，一起共建见美好。
      </p>

      <div class="section qrcode-section">
        <img src="https://7072-prod-9gallg7wd0327bcb-1325141975.tcb.qcloud.la/save/feedback/qrcode.png" alt="联系人二维码"
          class="qrcode">
        <div class="qrcode-text">
          <p>一起见行美好的新竹</p>
          <p>2024年04月23日</p>
        </div>
      </div>

      <!-- 结束 -->
      <div class="end-section">
        <div class="divider"></div>
        <span>END</span>
        <div class="divider"></div>
      </div>



    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import SdxNumberedIndexVue from '@/components/SdxNumberedIndex.vue';
import SdxSubtitleVue from '@/components/SdxSubtitle.vue';
import SdxTextIndexVue from '@/components/SdxTextIndex.vue';

export default defineComponent({
  name: 'App',
  components: {
    SdxNumberedIndexVue,
    SdxSubtitleVue,
    SdxTextIndexVue
  }
});
</script>

<style lang="less" scoped>

.container {
  width: 100vw;

  // 整体文字样式设置

  font-size: @sdx1-font-size;
  line-height: @sdx1-line-height;
  letter-spacing: @sdx1-letter-spacing;




  .body {
    padding: 0 20px;

    margin-top: 22px;

    .section {
      margin-bottom: @sdx1-section-gap;
    }

    .cover {
      object-fit: cover;
    }

    /* 列表容器样式 */
    .index-list {
      list-style-type: decimal;
      counter-reset: item;
      padding-left: 20px;

      color: @sdx1-color-primary;

      li {
        margin-bottom: 20px;
      }
    }

    .qrcode-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .qrcode {
        width: 98px;
        height: auto;
      }

      .qrcode-text {
        p {
          text-align: right;
        }
      }
    }

    .end-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      margin-bottom: 20px;

      .divider {
        width: 40px;
        height: 1px;
        background: #408E7C;
      }

      span {
        color: @sdx1-color-primary;

        font-weight: bold;
        font-size: @sdx1-font-size-sm;

        
      }
    }




  }


}
</style>