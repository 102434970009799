import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

//引入路由器
import router from '@/router/index'

import "@/styles/variables.less";
import "@/styles/global.less";
import "@/styles/clear.less";


app.use(router);

app.mount('#app')